import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "home" }
const _hoisted_2 = ["src"]
const _hoisted_3 = ["onClick"]

import { ref, reactive } from 'vue'
import { useRouter } from 'vue-router'
import PageImage from '@/assets/images/activeityPage.png'

export default /*@__PURE__*/_defineComponent({
  __name: 'mobile',
  props: {},
  emits: [],
  setup(__props, { expose: __expose, emit: __emit }) {

const props = __props
const emits = __emit
const router = useRouter()

const areaList = ref([
  { x: 3.85, y: .2, width: 1.67, height: .6, href: '/' },
  { x: 5.65, y: .2, width: 1.67, height: .6, href: '/rule' },
  // { x: 4.95, y: 11.75, width: 2.25, height: 1.35, href: '/group' },

])

__expose({})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      src: _unref(PageImage),
      alt: ""
    }, null, 8, _hoisted_2),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(areaList.value, ({ x, y, width, height, href }, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        onClick: ($event: any) => (_unref(router).push(href)),
        class: "absolute cursor-pointer",
        style: _normalizeStyle({ left: x + 'rem', top: y + 'rem', width: width + 'rem', height: height + 'rem' })
      }, null, 12, _hoisted_3))
    }), 128))
  ]))
}
}

})